import request from '@/utils/request'


// 设置启用状态 /admin/setting/setEnableStatus
export function setEnableStatus(query) {
    return request({
        url: '/admin/setting/setEnableStatus',
        method: 'post',
        data: query
    })
}

// add post
export function callMethod(query, url, method = 'post') {
    if (!url || !method) {
        return false
    } else {
        return request({
            url: url,
            method,
            data: query
        })
    }
}

//快捷回复
export function getQuickReplyList(query) {
    return request({
        url: '/admin/setting/getQuickReplyList',
        method: 'get',
        params: query
    })
}

//删除快捷回复
export function delQuickReply(query) {
    return request({
        url: '/admin/setting/delQuickReply',
        method: 'post',
        data: query
    })
}

//编辑快捷回复
export function editQuickReply(query) {
    return request({
        url: '/admin/setting/editQuickReply',
        method: 'post',
        data: query
    })
}

//添加 编辑快捷回复
export function addQuickReply(query, url) {
    if (!url) {
        return false
    } else {
        return request({
            url: url,
            method: 'post',
            data: query
        })
    }

}


//获取科室列表 /admin/setting/getHospitalDepartList
export function getHospitalDepartList(query) {
    return request({
        url: '/admin/setting/getHospitalDepartList',
        method: 'get',
        params: query
    })
}
// 删除科室 delHospitalDepart
export function delHospitalDepart(query) {
    return request({
        url: '/admin/setting/delHospitalDepart',
        method: 'post',
        data: query
    })
}

// 添加科室 /admin/setting/addHospitalDepart
export function addHospitalDepart(query) {
    return request({
        url: '/admin/setting/addHospitalDepart',
        method: 'post',
        data: query
    })
}


//擅长病症列表  /admin/setting/getHospitalSymptomList
export function getHospitalSymptomList(query) {
    return request({
        url: '/admin/setting/getHospitalSymptomList',
        method: 'get',
        params: query
    })
}

//删除病症  /admin/setting/delHospitalSymptom
export function delHospitalSymptom(query) {
    return request({
        url: '/admin/setting/delHospitalSymptom',
        method: 'post',
        data: query
    })
}


//用药时间  /admin/drugManage/getDrugMethodList
export function getDrugMethodList(query) {
    return request({
        url: '/admin/drugManage/getDrugMethodList',
        method: 'post',
        data: query
    })
}

//用药禁忌 /admin/drugManage/getDrugContraindicationList
export function getDrugContraindicationList(query) {
    return request({
        url: '/admin/drugManage/getDrugContraindicationList',
        method: 'post',
        data: query
    })
}

// 病情描述 /admin/setting/getDrugDescList
export function getDrugDescList(query) {
    return request({
        url: '/admin/setting/getDrugDescList',
        method: 'post',
        data: query
    })
}

//  临床诊断列表 /admin/drugManage/getDrugDiagnosisList
export function getDrugDiagnosisList(query) {
    return request({
        url: '/admin/drugManage/getDrugDiagnosisList',
        method: 'get',
        params: query
    })
}

//  临床诊断列表 /admin/drugManage/getDisease
export function getDisease(query) {
    return request({
        url: '/admin/drugManage/getDisease',
        method: 'get',
        params: query
    })
}

//科室联动表
export function departList() {
    return request({
        url: '/admin/setting/getDepartList',
        method: 'get'
    })
}



//热搜 列表  /admin/setting/getHotSearchList
export function getHotSearchList(query) {
    return request({
        url: '/admin/setting/getHotSearchList',
        method: 'get',
        params: query
    })
}

//设置*热搜 - 获取热搜详情 /admin/setting/getHotSearch
export function getHotSearch(query) {
    return request({
        url: '/admin/setting/getHotSearch',
        method: 'get',
        params: query
    })
}

//  banner /admin/helpManage/getCarouselList
export function getBanner(query) {
    return request({
        url: '/admin/carousel/carousel_list',
        method: 'get',
        params: query
    })
}

//删除banner  /admin/carousel/delCarousel
export function delBanner(query) {
    return request({
        url: '/admin/carousel/delCarousel',
        method: 'get',
        params: query
    })
}
//设置banner状态  /admin/carousel/saveStatusCarousel
export function saveStatusCarousel(query) {
    return request({
        url: '/admin/carousel/saveStatusCarousel',
        method: 'get',
        params: query
    })
}

// 评价列表  /admin/infoManage/getOrderAppraiseList
export function getOrderAppraiseList(query) {
    return request({
        url: '/admin/infoManage/getOrderAppraiseList',
        method: 'get',
        params: query
    })
}


// 评价列表  /admin/qiniu/upload-token
export function getQNToken() {
    return request({
        url: '/admin/qiniu/upload-token',
        method: 'get',
    })
}


// 机构列表  /admin/organizations
export function getOrgList(params) {
    return request({
        url: '/admin/organizations',
        method: 'get',
        params: params
    })
}

// 医院列表  /admin/org/org_list
export function orgList(params) {
    return request({
        url: '/admin/org/org_list',
        method: 'get',
        params: params
    })
}


// 医院禁用启用  /admin/org/saveStatusOrg
export function saveStatusOrg(params) {
    return request({
        url: '/admin/org/saveStatusOrg',
        method: 'get',
        params: params
    })
}


// 医院删除 /admin/org/delOrg
export function delHostpital(params) {
    return request({
        url: '/admin/org/delOrg',
        method: 'get',
        params: params
    })
}

// 商户列表
export function getMerchantList(params) {
    return request({
        url: '/admin/merchant',
        method: 'get',
        params
    })
}

// 添加商户
export function addMerchant(data) {
    return request({
        url: '/admin/merchant',
        method: 'post',
        data
    })
}

// 修改商户
export function updateMerchant(id, data) {
    return request({
        url: `/admin/merchant/${id}/update`,
        method: 'put',
        data
    })
}

// 修改商户状态
export function statusMerchant(id, status) {
    return request({
        url: `/admin/merchant/${id}/status`,
        method: 'put',
        data: { status }
    })
}


// 修改商户状态
export function merchantDetail(id) {
    return request({
        url: `/admin/merchant/${id}/detail`,
        method: 'get',
    })
}


export function getTopDeparts() {
    return request({
        url: `/admin/topDepart`,
        method: 'get',
    })
}
// 绑定数据列表
export function bindDataList(params) {
    console.log(params, 'params====================paramsparamsparams')
    return request({
        url: `/admin/butlers`,
        method: 'get',
        params
    })
}


export function dictsList(params) {
    return request({
        url: `/admin/hospital/index`,
        method: 'get',
        params
    })
}

export function dictsStatus(id, status) {
    return request({
        url: `/admin/hospital/status/${id}`,
        method: 'put',
        data: { status }
    })
}

export function symptomStatus(id, status) {
    return request({
        url: `/admin/hospital/symptomStatus/${id}`,
        method: 'put',
        data: { status }
    })
}

export function diseaseStatus(id, status) {
    return request({
        url: `/admin/hospital/diseaseStatus/${id}`,
        method: 'put',
        data: { status }
    })
}

export function departStatus(id, status) {
    return request({
        url: `/admin/hospital/departStatus/${id}`,
        method: 'put',
        data: { status }
    })
}

export function orgStatus(id, status) {
    return request({
        url: `/admin/hospital/orgStatus/${id}`,
        method: 'put',
        data: { status }
    })
}
export function departTopStatus(id, is_recom) {
    return request({
        url: `/admin/hospital/departTopStatus/${id}`,
        method: 'put',
        data: { is_recom }
    })
}

export function hospitalTopStatus(id, is_recom) {
    return request({
        url: `/admin/hospital/hospitalTopStatus/${id}`,
        method: 'put',
        data: { is_recom }
    })
}

export function getHospital(keyword) {
    return request({
        url: `/admin/doc/getHospital`,
        method: 'get',
        params: { keyword }
    })
}
export function getAddress(id) {
    return request({
        url: `/admin/doc/getAddress`,
        method: 'get',
        params: { id }
    })
}

export function addDoc(data) {
    return request({
        url: `/admin/doc/store`,
        method: 'post',
        data
    })
}

export function addDoctor(data) {
    return request({
        url: `/admin/doc/create`,
        method: 'post',
        data
    })
}

export function getConfig() {
    return request({
        url: `/admin/system/config`,
        method: 'get',
    })
}


export function saveConfig(data) {
    return request({
        url: `/admin/system/saveConfig`,
        method: 'post',
        data
    })
}

// 用法管理 用法列表
export function getTakeMethod(params) {
    return request({
        url: 'admin/takeMethod/List',
        method: 'GET',
        params
    })
}

// 用法管理 添加用法
export function postTakeMethodAddMethod(data) {
    return request({
        url: 'admin/takeMethod/addMethod',
        method: 'POST',
        data
    })
}

// 用法管理 修改用法
export function postTakeMethodUpdateMethod(data) {
    return request({
        url: 'admin/takeMethod/updateMethod',
        method: 'POST',
        data
    })
}

// 用法管理 删除用法
export function postTakeMethodDeleteMethod(data) {
    return request({
        url: 'admin/takeMethod/deleteMethod',
        method: 'POST',
        data
    })
}

// 用法管理 启用禁用 用法
export function postTakeMethodMethodStatus(data) {
    return request({
        url: 'admin/takeMethod/methodStatus',
        method: 'POST',
        data
    })
}
//医生用语列表
export function getQuickList(params) {
    return request({
        url: 'admin/quick/list',
        method: 'GET',
        params
    })
}
//删除医生用语
export function getQuickInfoDel(id) {
    return request({
        url: `admin/quick/del/${id}`,
        method: 'DELETE'
    })
}
//新增医生用语
export function getQuickInfoAdd(data) {
    return request({
        url: `admin/quick/add`,
        method: 'POST',
        data
    })
}
//编辑医生用语
export function getQuickInfoEdit(data, id) {
    return request({
        url: `admin/quick/edit/${id}`,
        method: 'POST',
        data
    })
}
//医生用语开关
export function getQuickInfoInput() {
    return request({
        url: `admin/quick/input`,
        method: 'get',
    })
}

export function operateReport(params) {
    return request({
        url: `admin/data/statistics`,
        method: 'get',
        params
    })
}
//病症管理热搜开关
export function getHotSearchStatus(data) {
    return request({
        url: `admin/setSymptomHot/${data.id}`,
        method: 'POST',
        data
    })
}









