<template>
  <div class="gg-container">
    <div class="search-container">
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('delete') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="handleDel(multipleSelectionPres[0].id)"
            v-if="multipleSelectionPres.length == 1"
            >删除
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="editReply('edit', multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >编辑
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('add') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="editItem('add')"
            >新增
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
      </div>
      <!--对应第三方图标的button样式-->
    </div>
    <!----------------表格---------------->

    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="editArticle('view', scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.enable_status"
              active-value="启用"
              inactive-value="关闭"
              @change="inputSwitch(scope.row)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a v-if="column.column_prop == 'supervise_status'">
              {{ scope.row.supervise_status | formatSuperviseStatus }}
            </a>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'group_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.group_name)
                "
                v-model="searchParams.group_id"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="'trigger' == column.column_prop"
                style="width: 200px"
                @change="handleSearch(column.column_prop, searchParams.trigger)"
                v-model="searchParams.trigger"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="'from_type' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.from_type)
                "
                v-model="searchParams.from_type"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in from_type_List"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="'client' == column.column_prop"
                style="width: 200px"
                @change="handleSearch(column.column_prop, searchParams.client)"
                v-model="searchParams.client_type"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="(item, index) in clientList"
                  :key="index"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <EleDiglog ref="EleDiglog" @getList="getList" />
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import EleDiglog from "./components/eleDiglog";
import {
  getQuickList,
  getQuickInfoDel,
  getQuickInfoEdit,
  getQuickInfoInput,
} from "@/api/basic/index";
import { mapState } from "vuex";
export default {
  name: "doctorLanguage",
  components: {
    Pagination,
    // eslint-disable-next-line vue/no-unused-components
    EleDiglog,
    editTableHead,
  },
  computed:{
    ...mapState({
      table_options: (state) => state.user.table_options,
    })
  },
  data() {
    return {
      table_type:'doctorLanguage',
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      // multipleSelection1: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "group_name",
          isSearch: false,
        },
        {
          name: "from_type",
          isSearch: false,
        },
        {
          name: "trigger",
          isSearch: false,
        },
        {
          name: "client",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      from_type_List: [
        {
          value: 1,
          label: "平台",
        },
        {
          value: 2,
          label: "机构",
        },
        {
          value: 3,
          label: "医生",
        },
      ],
      options: [
        {
          value: 1,
          label: "等待接诊",
        },
        {
          value: 2,
          label: "开始接诊",
        },
        {
          value: 3,
          label: "病历书写",
        },
        {
          value: 4,
          label: "病例签章",
        },
        {
          value: 5,
          label: "处方书写",
        },
        {
          value: 6,
          label: "处方签章",
        },
        {
          value: 7,
          label: "问诊结束",
        },
      ],
      typeList: [
        {
          value: 1,
          label: "自动提示",
        },
        {
          value: 2,
          label: "常用回复",
        },
      ],
      listLoading: false,
      total: 0,
      searchForm: {},
      dialogVisible: false,
      tableData: [],
      deleteIds: [],
      quickInfo: {},
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
        client_type: "",
      },
      searchParams: {
        orderTime: [],
      },
      clientList: ["", "医生", "医助"],
    };
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      let tmp = val.map((v, k, a) => {
        return v.appraise_id;
      });
      this.multipleSelection = `[${tmp.join(",")}]`;
      console.log(this.multipleSelection, 43435435);
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "doctorLanguage",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.doctorLanguage;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    //开关
    inputSwitch(e) {
      console.log("开关", e);
      let data = {
        id: e.id,
        enable_status: e.enable_status == "关闭" ? 2 : 1,
      };
      getQuickInfoEdit(data, data.id)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
            this.getList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //编辑
    editReply(type, row) {
      console.log("编辑", type, row);
      this.$refs.EleDiglog.dialogVisible = true;
      this.$refs.EleDiglog.type = type;
      this.$refs.EleDiglog.info = row;
    },
    _getQuickInfoInput() {
      getQuickInfoInput().then((res) => {
        if (res.code == 200) {
          this.clientList = res.data.client_list;
        }
      });
    },
    //删除
    handleDel(row) {
      console.log("删除", row);
      //确认提示框
      this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getQuickInfoDel(row)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
                this.getList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editItem(type, row) {
      console.log(type, row);
      console.log(this.$refs.EleDiglog.dialogVisible);
      this.$refs.EleDiglog.type = type;
      this.$refs.EleDiglog.dialogVisible = true;
    },
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getQuickList(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
    this._getQuickInfoInput();
  },
};
</script>

<style lang="scss" scoped>
.gg-container {
  position: relative;
}
</style>
