<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="30%" :show-close="false">

      <div class="top-content">
        <span>客户端类型 : </span>
        <div>
          <el-radio
              v-model="client_type"
              :label="item.value"
              v-for="item in clientList"
              :key="item.value"
          >{{ item.label }}</el-radio
          >
        </div>
      </div>


      <div class="top-content">
        <span>发送类型 : </span>
        <div>
          <el-radio
            v-model="radio"
            :label="item.value"
            v-for="item in typeList"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </div>
      </div>
      <div class="top-content" v-if="triggerTimiFlag == true">
        <span>触发节点 : </span>
        <div style="flex:1;">
          <el-select v-model="value" placeholder="请选择" @change="onChange">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="top-content">
        <span>类型内容 : </span>
        <div style="flex:1;">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="textareaValue"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getQuickInfoAdd, getQuickInfoEdit } from "@/api/basic/index";
export default {
  name: "EleDiglog",
  data() {
    return {
      dialogVisible: false,
      triggerTimiFlag: true, //触发时机
      info: {},
      type: "",
      typeList: [
        {
          value: 1,
          label: "自动提示",
        },
        {
          value: 2,
          label: "常用回复",
        },
      ],
      radio: 1,
      textareaValue: "",
      options: [
        {
          value: 1,
          label: "等待接诊",
        },
        {
          value: 2,
          label: "开始接诊",
        },
        {
          value: 3,
          label: "病历书写",
        },
        {
          value: 4,
          label: "病例签章",
        },
        {
          value: 5,
          label: "处方书写",
        },
        {
          value: 6,
          label: "处方签章",
        },
        {
          value: 7,
          label: "问诊结束",
        },
      ],
      value: "",
      clientList: [
        {value: 1,label: '医生'},
        {value: 2,label: '医助'},
      ],
      client_type: 1,
    };
  },
  methods: {
    onChange(e) {
      console.log("onChange", e, this.value);
    },
    //编辑
    edit() {
      let id = this.info.id;
      let data = {
        group_id: this.radio,
        quick_reply: this.textareaValue,
        trigger: this.value,
        client_type: this.client_type,
      };
      getQuickInfoEdit(data, id)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.$emit("getList");
            this.value = "";
            this.textareaValue = "";
            this.radio = 1;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err.msg);
        });
    },
    //新增
    add() {
      let data = {
        group_id: this.radio,
        quick_reply: this.textareaValue,
        trigger: this.value,
        client_type: this.client_type,
      };
      getQuickInfoAdd(data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.$emit("getList");
            this.value = "";
            this.textareaValue = "";
            this.radio = 1;
            this.client_type = 1;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err.msg);
        });
    },
    confirm() {
      console.log("confirm", this.quickInfo);
      if (this.type == "add") {
        this.add();
      } else {
        this.edit();
      }
    },
  },
  //监视
  watch: {
    info: {
      handler: function (val, oldVal) {
        console.log("info", val, oldVal);
        this.radio = val.group_name == '提示用语'? 1 : 2;
        this.textareaValue = val.quick_reply;
        this.options.forEach((item) => {
          if (item.label == val.trigger) {
            this.value = item.value;
          }
        });
        this.client_type = val.client_type
      },
      deep: true,
    },
    radio: {
      handler: function (val, oldVal) {
        console.log("radio", val, oldVal);
        val == 1 ? (this.triggerTimiFlag = true) : (this.triggerTimiFlag = false);
        val == 2 ? this.value = 0 : this.value = "";
      },
      deep: true,
    },
    type: {
      handler: function (val, oldVal) {
        console.log("type", val, oldVal);
        if(val == 'add'){
          this.radio = 1;
          this.value = "";
          this.textareaValue = "";
          this.client_type = "";
        }
      },
      deep: true,
    },

    client_type: {
      handler: function (val, oldVal) {
        console.log("client_type", val, oldVal);
      },
      deep: true,
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.top-content {
  padding: 20px 20px 0 20px;
  display: flex;
  font-size: 16px;
  font-weight: bold;
}
</style>
