import { render, staticRenderFns } from "./doctorLanguage.vue?vue&type=template&id=add43c10&scoped=true&"
import script from "./doctorLanguage.vue?vue&type=script&lang=js&"
export * from "./doctorLanguage.vue?vue&type=script&lang=js&"
import style0 from "./doctorLanguage.vue?vue&type=style&index=0&id=add43c10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "add43c10",
  null
  
)

export default component.exports